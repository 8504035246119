@import 'src/assets/sass/abstracts/variables';
@import 'src/assets/sass/abstracts/mixins';

.errorBoundary {
  width: 100vw;
  height: 100vh;
  margin-top: -1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    margin-bottom: get-theme-spacing(5);
  }
}
