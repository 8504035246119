@import 'src/assets/sass/abstracts/variables';
@import 'src/assets/sass/abstracts/mixins';

.container {
  width: 80%;
  margin: auto;
  max-width: 782px;
  padding-bottom: get-theme-spacing(5);
  @media (max-width: 600px) {
    width: 90%;
    padding-bottom: get-theme-spacing(0);
  }
}

.formFieldContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: get-theme-spacing(3);
  margin-bottom: get-theme-spacing(4);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: get-theme-spacing(2);
  }
}

.formButtons {
  text-align: right;

  button {
    margin-left: get-theme-spacing(2);
  }

  @media (max-width: 600px) {
    button {
      width: 100%;
      margin-left: 0 !important;
      margin-bottom: get-theme-spacing(2);
    }
  }
}
